/* Navbar.css */

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #d5c2c2;
    color: #fff;
    padding: 1rem;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
}

.menu-button {
    display: none;
    flex-direction: column;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
}

.menu-button span {
    height: 3px;
    width: 25px;
    background-color: #fff;
    margin: 3px 0;
    transition: 0.4s;
}

.menu-button.open span:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.menu-button.open span:nth-child(2) {
    opacity: 0;
}

.menu-button.open span:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}

.nav-links {
    list-style: none;
    display: flex;
}

.nav-links li {
    color: var(--clr-primary);
    font-size: 20px;

}

.nav-links li a {
    text-decoration: none;
    margin: 10px;
}

.nav-links.open {
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 0;
    background-color: #333;
    width: 100%;
    text-align: center;
    transition: 0.4s;
    z-index: 1;
}

.nav-links.open li {
    padding: 1rem 0;
}


.nav-links.open a {
    color: var(--clr-primary);
    /* Use the primary color for menu item text */
    text-decoration: none;
    font-size: 1rem;
}

/* Style for menu item links on hover */
.nav-links.open a:hover {
    color: var(--clr-accent-two);
    /* Use the accent-two color on hover */
}

/* Style for active menu item link (if needed) */
.nav-links.open a.active {
    color: var(--clr-secondary);
    /* Use the secondary color for active menu items */
}

@media screen and (max-width: 768px) {
    .menu-button {
        display: flex;
    }

    .nav-links {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 70px;
        right: 0;
        background-color: #333;
        text-align: center;
        transition: 0.4s;
        z-index: 1;
    }

    .nav-links.open {
        display: flex;
    }
}