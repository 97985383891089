.scroll-container {
  overflow: hidden;

}

.text-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: top 0.9s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
  font-size: 3vw;
  font-family: var(--ff-accent);

  /* Responsive font size */
}

.text-item.active {
  top: 10%;
  opacity: 1;
}

.text-item.inactive {
  top: -20px;
  opacity: 0;
}

.subtext-item {
  position: absolute;
  top: 15%;
  right: 30%;
  opacity: 0;
  transition: opacity 0.5s, transform 2s;
  transform: translateY(20px);
  white-space: nowrap;
  font-size: 2vw;
  font-family: var(--ff-secondary);
  left: 50px;
  /* Responsive font size */
}

.text-item.active+.subtext-item,
.subtext-item.active {
  opacity: 1;
  transform: translateY(0px);
}

@media (max-width: 768px) {
  .text-item {
    font-size: 5vw;
    /* Adjust font size for smaller screens */
  }

  .subtext-item {
    font-size: 4vw;
    /* Adjust font size for smaller screens */
  }
}