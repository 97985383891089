@font-face {
  font-family: 'Arvo';
  font-display: auto;
  src: local('Arvo'), url(https://fonts.gstatic.com/s/arvo/v9/rC7kKhY-eUDY-ucISTIf5PesZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
}

:root {
  --clr-primary: #092144;
  --clr-secondary: #8a2be2;
  --clr-accent: #ffd300;
  --clr-accent-two: #4fc3f7;
  --clr-font-colour: #2e3d49;
  --clr-neutral: white;



  --ff-primary: "Roboto", sans-serif;
  --ff-accent: 'Orbitron', sans-serif;
  --ff-secondary: 'Space Mono', monospace;
  --ff-auxillary: 'Major Mono Display', monospace;
}



.App {
  text-align: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 3rem;
  min-height: 100vh;
}

.App-logo {
  height:10vmin;
  pointer-events: none;
  width: fit-content;
}

/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

*/

.App-header {
  background-color: var(--pure-white);
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.gallery-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  border: 10px solid black;
  width: fit-content;
  padding: 0.50rem;
}



button {
  margin: 0 20px;
}

img {
  max-width: 100%;
  width:600px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

button {
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .gallery-container {
    flex-direction: column;
  }
}


.spinner {
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 5px solid black;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}


.image-wrapper {
  position: relative;
  width: 100%;
  height: auto;
}

.styled-img {
  width: 100%;
  max-height: 400px;
  max-width: 600px;
}

.backward-icon,
.forward-icon {
  position: absolute;
  top: 50%;
  font-size: 2em;
  color: white;
  cursor: pointer;
  z-index: 1;
  transform: translateY(-50%);
}

.backward-icon {
  left: 10px;
}

.forward-icon {
  right: 10px;
}

@media (max-width: 768px) {

  .backward-icon,
  .forward-icon {
    font-size: 1em;
  }
}


.email-form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
}

.email-form-container div {
  flex: 1;
  padding: 0.50rem;
}

.email-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;


}

.email-form div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  ;
}

.email-form input,
.email-form textarea,
.email-form button {
  padding: 10px;
  border-radius: 5px;
  width: fit-content;
  margin-bottom: 10px;
  width: 80%;
}

label {
  display: block;
  font-weight: 400;
  font-size: clamp(18px, 2vw, 20px);
  text-align: start;
  margin-left: 10px;
  font-family: var(--ff-primary);
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  width: 80%;
  padding: 10px;
  border-radius: 5px;
  font-size: clamp(18px, 2vw, 20px);
  border-bottom-color: hsla(0, 100%, 50%, 0.1);
  border-left-color: hsla(0, 100%, 50%, 0.1);
  box-sizing: border-box;
  resize: vertical;
  border-right: none;
  border-top: none;
}

input[type="text"]:hover,
input[type="email"]:hover,
input[type="tel"]:hover,
textarea:hover {
  box-shadow: 0 0 5px orangered;
}

input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: 0 0 30px hsla(0, 100%, 50%, 0.1);
  ;
}



input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.error {
  color: red;
  font-size: 0.8em;
}

.css-button-arrow--rose {
  min-width: 130px;
  width: fit-content;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  overflow: hidden;
  border-radius: 5px;
  border: none;
  background-color: #ff6392;
  font-family: var(--ff-secondary);
}

.css-button-arrow--rose:hover {
  border-radius: 5px;
  padding-right: 24px;
  padding-left: 8px;
}

.css-button-arrow--rose:hover:after {
  opacity: 1;
  right: 10px;
}

.css-button-arrow--rose:after {
  content: "\00BB";
  position: absolute;
  opacity: 0;
  font-size: 20px;
  line-height: 40px;
  top: 0;
  right: -20px;
  transition: 0.4s;
}



.animate-line1,
.animate-line2,
.animate-line3 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 2s linear forwards, changeColor 4s ease-in-out infinite;
  animation-iteration-count: infinite;
  animation-delay: 0s, 5s;
  /* First value for draw, second for changeColor */
}

@keyframes draw {
  from {
    stroke-dashoffset: 1000;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes changeColor {

  0%,
  100% {
    stroke: currentColor;
  }

  50% {
    stroke: red;
    /* Change to your preferred color */
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ionicon {
  animation: rotate 6s linear infinite;
  max-width: 200px;
}